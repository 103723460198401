import Image, { StaticImageData } from 'next/image';
import Link from 'next/link';

interface Props {
  right?: boolean;
  title: string;
  subtitle: string;
  image: StaticImageData;
  handleClick: () => void;
}

const Collection = ({
  right = false,
  title,
  subtitle,
  image,
  handleClick,
}: Props) => {
  return (
    <div
      className={`${
        right && 'flex-row-reverse self-end'
      } -mb-8 flex h-96 gap-4 sm:gap-8`}
    >
      <div className="w-48 h-80 sm:w-64 xl:h-auto xl:w-80">
        <Image
          src={image}
          alt={title}
          width={400}
          height={500}
          className="object-cover object-center w-full h-full"
          // placeholder="blur"
        />
      </div>
      <div
        className={`mt-10 flex flex-col ${
          right ? 'items-end text-right' : 'items-start text-left'
        }`}
      >
        <h2 className="-mb-2 text-3xl font-bold sm:text-5xl">{title}</h2>
        <h3 className="w-2/3 text-sm ">{subtitle}</h3>
        <Link href="/product">
          <a className="mt-2 btn w-max" onClick={handleClick}>
            Explore
          </a>
        </Link>
      </div>
    </div>
  );
};

export default Collection;
