import { useSetRecoilState } from 'recoil';

import filterAtom, { defaultFilter } from '@/common/recoil/filter';
import kidImage from '@/public/img/3.png';
import menImage from '@/public/img/1.png';
import moreImage from '@/public/img/4.png';
import womenImage from '@/public/img/2.png';

import Collection from './Collection';

const CollectionList = () => {
  const setFilter = useSetRecoilState(filterAtom);

  return (
    <div className="py-28">
      <div className="flex flex-col items-center w-full md:px-16 lg:px-0">
        <div className="w-full lg:w-[40rem] lg:translate-x-20 xl:w-[60rem]">
          <Collection
            title="Phone Cases"
            subtitle="Elevate your phone's style with chic and protective cases – where fashion meets function!"
            image={menImage}
            handleClick={() =>
              setFilter({
                ...defaultFilter,
                gender: {
                  ...defaultFilter.gender,
                  'Phone Cases': true,
                },
              })
            }
          />
          <Collection
            title="Power Banks"
            subtitle="Stay charged and connected on the go with our sleek and reliable power banks."
            image={womenImage}
            right
            handleClick={() =>
              setFilter({
                ...defaultFilter,
                gender: {
                  ...defaultFilter.gender,
                  'Power Banks': true,
                },
              })
            }
          />
        </div>

        <div className="w-full lg:w-[40rem] lg:-translate-x-20 xl:w-[60rem]">
          <Collection
            title="Screen Protectors"
            subtitle="Clarity and protection in one! Keep your screen flawless with our crystal-clear protectors."
            image={kidImage}
            handleClick={() =>
              setFilter({
                ...defaultFilter,
                gender: {
                  ...defaultFilter.gender,
                  'Screen Protectors': true,
                },
              })
            }
          />
          <Collection
            title="Selfie Sticks"
            subtitle="Capture perfect moments with ease using our portable, Bluetooth-ready selfie sticks."
            image={moreImage}
            right
            handleClick={() =>
              setFilter({
                ...defaultFilter,
                gender: {
                  ...defaultFilter.gender,
                  'Selfie Sticks': true,
                },
              })
            }
          />
        </div>
      </div>
    </div>
  );
};
export default CollectionList;
