import type { NextPage } from 'next';

import Home from '@/modules/home/components/Home';

const HomePage: NextPage = () => {
  // return <div/>;
  return <Home />;
};

export default HomePage;
