import { motion } from 'framer-motion';
import Image from 'next/image';

import Footer from '@/common/components/footer/components/Footer';
import mainImage from '@/public/img/main.png';

import {
  collectionListAnimation,
  imageAnimation,
  textAnimation,
} from '../animations/Home.animations';
import CollectionList from './CollectionList';

const headerStyle =
  'text-5xl md:text-6xl xl:text-extra font-extrabold xl:-mt-12 -mt-5 md:-mt-8';

const Home = () => {
  return (
    <div className="flex items-center justify-center w-full h-full">
      <motion.div
        className="relative w-64 h-64 sm:h-96 sm:w-96"
        variants={imageAnimation}
        animate="to"
      >
        <motion.div
          className="absolute mb-0 ml-5 bottom-full md:ml-10 lg:-mb-5 xl:ml-24 2xl:mb-5 2xl:ml-48"
          variants={textAnimation}
          initial="from"
          animate="to"
        >
          <h1 className={headerStyle}>PhoneHub,</h1>
          <h1 className={`${headerStyle}`}>
            Elevate Your Mobile Experience.
          </h1>
          {/* <h1 className={`${headerStyle} block sm:hidden`}>Enjoy the</h1> */}
          {/* <h1 className={`${headerStyle} block sm:hidden`}>life.</h1> */}
        </motion.div>
        <Image
          src={mainImage}
          alt=""
          className="object-cover object-center w-full h-full"
          priority
          // placeholder="blur"
        />
        <motion.div
          variants={collectionListAnimation}
          initial="from"
          animate="to"
        >
          <CollectionList />
          <Footer />
        </motion.div>
      </motion.div>
    </div>
  );
};

export default Home;
